$(function() {
      
    // Define the dataLayer object
    window.dataLayer = window.dataLayer || [];    

    // Declare the hero logo bodies var
    var logo_bodies = [];

    // VH variable - mostly used for mobile screen height calculation
    // as Safari is a total shit of a browser
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');

    $(window).on('load', function() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
    });
    
    // Set the color scheme
    $('body').attr(
        'data-color-scheme', 
        localStorage.getItem('color-scheme') !== null ? localStorage.getItem('color-scheme') : window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light'
    );

    // Switch on button click
    $('header ul li button').on('click', function(e) {
        e.preventDefault();

        var curr_scheme = $('body').attr('data-color-scheme'),
            new_scheme  = curr_scheme === 'dark' ? 'light' : 'dark';

        $('body').attr('data-color-scheme', new_scheme);
        localStorage.setItem('color-scheme', new_scheme);

        $.each(logo_bodies, function() {
            this.render.sprite.texture = this.render.sprite.texture.replace(new_scheme, curr_scheme);
        });
    });

    // Init the Magic Mouse
    magicMouse({
        'outerStyle': 'circle',
        'hoverEffect': 'circle-move',
        'hoverItemMove': false,
        'defaultCursor': false,
        'outerWidth': 30,
        'outerHeight': 30
    });

    // Append SVG logos in relevant places
    $('[data-append-logo]').each(function() {

        var elem = $(this),
            logo = logos[
                elem.attr('data-append-logo') + (elem.attr('data-logo-mode') !== undefined ? '-' + elem.attr('data-logo-mode') : '')
            ];

        elem.html('<figure>' + logo + '</figure>').find('svg')
            .attr('style', elem.attr('data-logo-style'));

        elem.removeAttr('data-logo-style')
            .removeAttr('data-logo-mode')
            .removeAttr('data-append-logo');
    });

    // Header contact scrollto
    $('header').find('.button').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: $(document).height() }, 1500);
    });

    // Homepage hero interaction using Matter.js
    var world_canvas = $('section[style*="hero"]').find('figure');

    $(window).on('load', function() {

        // Create the engine and renderer
        var engine = Matter.Engine.create(),
            render = Matter.Render.create({
                element: world_canvas[0],
                engine: engine,
                options: {
                    width: world_canvas.outerWidth(),
                    height: world_canvas.outerHeight(),
                    pixelRatio: 2,
                    background: 'transparent',
                    wireframes: false
                }
            });
    
        // Add the basic bodies and bounds to the world
        var bounds_args = {
            isStatic: true,
            render: {
                fillStyle: 'transparent'
            }
        };

        Matter.World.add(engine.world, [
    
            // Ground
            Matter.Bodies.rectangle(
                world_canvas.outerWidth() / 2, 
                world_canvas.outerHeight() + 1, 
                world_canvas.outerWidth(), 1, 
                bounds_args
            ),
    
            // Roof
            Matter.Bodies.rectangle(
                world_canvas.outerWidth() / 2, 
                -1, world_canvas.outerWidth(), 
                1, 
                bounds_args
            ),
    
            // Left Wall
            Matter.Bodies.rectangle(
                -1, 
                world_canvas.outerHeight() / 2, 
                1, 
                world_canvas.outerHeight(), 
                bounds_args
            ),
    
            // Right Wall
            Matter.Bodies.rectangle(
                world_canvas.outerWidth() + 1, 
                world_canvas.outerHeight() / 2, 
                1, 
                world_canvas.outerHeight(), 
                bounds_args
            )
        ]);

        // Add the client logo circles
        var hero       = $('section[style*="hero"]'),
            hero_title = hero.find('h1'),
            spawn_bounds = [
                $(window).width() > 1024 ? hero_title.offset().left + hero_title.outerWidth() + 50 : 0,
                $(window).outerWidth()
            ],
            size_bounds  = {
                lg: {
                    xl: [60, 70],
                    lg: [45, 55],
                    sm: [30, 40],
                    xs: [30, 35]
                },
                md: {
                    xl: [40, 55],
                    lg: [30, 42],
                    sm: [25, 30],
                    xs: [20, 27]
                },
                sm: {
                    xl: [20, 35],
                    lg: [15, 27],
                    sm: [13, 20],
                    xs: [10, 17]
                }
            },
            logos_args   = {
                sizes: {
                    lg: ['mastercard', 'anthem', 'arison', 'dynamicyield', 'electra', 'intel', 'ituran', 'selina', 'strauss', 'tau', 'teva', 'bazan', 'energix'],
                    md: ['variscite', '3dsignals', 'almogim', 'spni', 'cybellum', 'drygair', 'dataloop', 'donnaitalia', 'dmci', 'ermetic', 'malamteam', 'freedomfarm', 'glossai', 'grin', 'ono', 'splitit', 'reflect', 'givatshmuel', 'tauventures', 'guesty', 'hyro', 'irpsystems', 'israelscouts', 'makorishon', 'nayax', 'onboard', 'panorays', 'playstudios', 'pubmatic', 'rapidapi', 'reinholdcohn', 'semperis', 'shimrit', 'starkware', 'storemaven', 'utila', 'voltasolar', 'inspirationgroup'],
                    sm: ['rapour', 'manhiguta', 'oneview', 'avivgroup', 'litmus7', 'barlev', 'bidalgo', 'brillianceventures', 'cds', 'kaballah', 'crispify', 'first', 'mazorfirst', 'oriient', 'workport', 'zimperium', 'openmessage', 'narrativ']
                },
                with_mode: ['almogim', 'avivgroup', 'barlev', 'dynamicyield', 'irpsystems', 'mazorfirst', 'selina', 'tau', 'utila', 'ozen', 'tauventures']
            },
            color_scheme = $('body').attr('data-color-scheme') === 'light' ? 'dark' : 'light',
            count        = 0,
            x_location,
            size;

        $.each(logos_args.sizes.lg.concat(logos_args.sizes.md, logos_args.sizes.sm), function() {

            var key          = this,
                size         = logos_args.sizes.lg.includes(key.toString()) ? 'lg' : (logos_args.sizes.md.includes(key.toString()) ? 'md' : 'sm'),
                res          = $(window).width() > 1480 ? 'xl' : $(window).width() > 1024 ? 'lg' : $(window).height() > 600 ? 'sm' : 'xs',
                _size_bounds = size_bounds[size][res];

            x_location  = Math.floor(Math.random() * (Math.floor(spawn_bounds[1]) - Math.ceil(spawn_bounds[0]) + 1)) + Math.ceil(spawn_bounds[0]);
            size        = Math.floor(Math.random() * (Math.floor(_size_bounds[1]) - Math.ceil(_size_bounds[0]) + 1)) + Math.ceil(_size_bounds[0]);
                    
            var item = Matter.Bodies.circle(x_location, 20, size, {
                render: {     
                    sprite: {
                        texture: '/assets/images/circles/' + (logos_args.with_mode.includes(key.toString()) ? key + '-' + color_scheme : key) + '.png', 
                        xScale: size / 70, 
                        yScale: size / 70
                    }
                }
            });

            logo_bodies.push(item);

            setTimeout(function() {
                Matter.World.add(engine.world, item);       
            }, count * 75);

            count++;
        });

        // Add mouse control
        var mouse = Matter.Mouse.create(render.canvas),
            mouse_constraint = Matter.MouseConstraint.create(engine, {
            mouse: mouse,
                constraint: {
                    stiffness: 0.5,
                    render: {
                        visible: false
                    }
                }
            });

        Matter.World.add(engine.world, mouse_constraint);

        // Keep the mouse in sync with rendering
        render.mouse = mouse;

        // Allow page scrolling in matter.js window
        mouse.element.removeEventListener('mousewheel', mouse.mousewheel);
        mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel);

        if($(window).width() <= 1024) {
            mouse_constraint.mouse.element.removeEventListener('touchstart', mouse_constraint.mouse.mousedown);
            mouse_constraint.mouse.element.removeEventListener('touchmove', mouse_constraint.mouse.mousemove);
            mouse_constraint.mouse.element.removeEventListener('touchend', mouse_constraint.mouse.mouseup);
            
            mouse_constraint.mouse.element.addEventListener('touchstart', mouse_constraint.mouse.mousedown, { passive: true });

            mouse_constraint.mouse.element.addEventListener('touchmove', (e) => {
                if (mouse_constraint.body) {
                    mouse_constraint.mouse.mousemove(e);
                }
            });

            mouse_constraint.mouse.element.addEventListener('touchend', (e) => {
                if (mouse_constraint.body) {
                    mouse_constraint.mouse.mouseup(e);
                }
            });    
        }
        
        // Add the final body to follow the cursor
        if($(window).width() > 990) {

            var cursor_follower = Matter.Bodies.rectangle(0, 0, 40, 40, {
                isStatic: true,
                chamfer: {
                    radius: 20
                }, 
                render: { 
                    fillStyle: 'transparent'
                }
            });

            Matter.World.add(engine.world, cursor_follower);
            Matter.Body.setDensity(cursor_follower, 200);

            Matter.Events.on(mouse_constraint, 'mousemove', function(e) {

                if (! e.mouse.position.x) {
                    return;
                }
            
                Matter.Body.setPosition(cursor_follower, { x: e.mouse.position.x, y: e.mouse.position.y }); 
            });
        }
        
        // Run the engine
        Matter.Engine.run(engine);
    
        // Run the renderer
        Matter.Render.run(render);
    });

    // About section - divide to <span>s for the interaction
    $('section[style*="about"]').find('p').each(function() {
        $(this).html('<span>' + $(this).html().split(' ').join('</span><span>') + '</span>');
    });

    // Init the gsap interaction
    var about_controller = new ScrollMagic.Controller();

    var spans = $('section[style*="about"]').find('p').first().find('span'),
        scene = new ScrollMagic.Scene({
            triggerElement: 'section[style*="about"]',
            triggerHook: 'onLeave',
            duration: spans.length * 25,
            reverse: true
        })
        .setPin('section[style*="about"] > div')
        .on('progress', function (e) {

            var words = Math.ceil(spans.length * e.progress);

            if(e.scrollDirection === 'FORWARD') {
                spans.eq(words).prevAll('span').addBack().addClass('read');
            } else if(words < spans.length) {
                spans.not(spans.eq(words).prevAll('span').addBack()).removeClass('read');
            }
        })
        .on('start', function(e) {
            spans.removeClass('read');
        })
        .on('end', function() {
            spans.addClass('read');
        })
        .addTo(about_controller);

    // Projects section interaction
    var current_color_scheme;

    $('section[style*="projects"]').find('li').on('mouseenter', function() {
        
        if($(window).width() > 1024) {
            current_color_scheme = $('body').attr('data-color-scheme');

            $('body').attr('data-color-scheme', $(this).attr('data-color-scheme'))
                .css({
                    background: $(this).attr('data-hover')
                });    
        }
    });

    $('section[style*="projects"]').find('li').on('mouseleave', function() {

        if($(window).width() > 1024) {
            $('body').attr('data-color-scheme', current_color_scheme);
            $('body').removeAttr('style');
        }
    });

    // Testimonials section interaction
    var testimonials_controller = new ScrollMagic.Controller(),
        testimonials_slider     = $('section[style*="testimonials"]').find('ul'),
        testimonials            = testimonials_slider.find('li'),
        initial_offset          = ($(window).width() - testimonials.outerWidth()) / 2,
        scroll_duration         = ((testimonials.length - 1) * testimonials.outerWidth()) + (testimonials.length - 1) * (
            $(window).width() > 1480 ? 200 : $(window).width() > 475 ? 100 : 0
        );

    var scene = new ScrollMagic.Scene({
            triggerElement: 'section[style*="testimonials"]',
            triggerHook: 'onLeave',
            duration: scroll_duration,
            reverse: true
        })
        .setPin('section[style*="testimonials"] > ul')
        .on('progress', function (e) {

            testimonials_slider.css({
                transform: 'translate(' + (initial_offset - e.progress * scroll_duration) + 'px, 0)'
            })
        })
        .addTo(testimonials_controller);

    // Init the contact chat's simpleBar scroller
    var contact_chat_bar = new SimpleBar($('section[style*="contact"]').find('ul')[0], {
        autoHide: false
    });

    // Handle the contact flow
    // Set the vars
    var contact_block   = $('section[style*="contact"]'),
        contact_chat    = contact_block.find('ul'),
        contact_form    = contact_block.find('form'),
        contact_field   = contact_form.find('input'),
        contact_skip    = contact_form.find('button[type="button"]'),
        is_contact      = [false, -1],
        contact_steps   = [
            'name', 'email', 'company', 'jobtitle', 'phone'
        ],
        contact_prompts = {
            name: 'What is your name?',
            email: [
                'Nice to meet you {}!',
                'What is your email address?'
            ],
            company: 'And where do you work?',
            jobtitle: 'And what is you do at {}?',
            jobtitle_skipped: 'What is your job title?',
            phone_skipped: 'Lastly, what is your phone number?',
            phone: 'Lastly, what is your phone number?',
            skip: [
                'Prefer not to tell',
                'It\'s a secret 🤫',
                'Let\'s skip this one'
            ],
            email_validation: 'Hmm... Are you sure that\'s an email address? Let\'s try again.',
            phone_validation: 'This phone number seems to be incorrect, let\'s try again!',
            name_validation: 'I\'m sorry, but I need to know your name.'                            
        },
        contact_info    = {};

    // On initial block click, focus on the input
    contact_block.on('click', function(e) {

        if(e.target.tagName.toLowerCase() !== 'a' && e.target.tagName.toLowerCase() !== 'button' && $(e.target).parents('form') !== undefined) {
            e.preventDefault();
            contact_field[0].focus();
        }
    });

    // On contact field focus, if the state of the form
    // is initial, start the contact flow
    contact_field.on('focus', function() {

        if(contact_block.attr('data-state') === 'initial') {

            // Dispatch a contactFlowStarted dataLayer event
            window.dataLayer.push({
                'event': 'contactFlowStarted'
            });

            contact_block.attr('data-state', 'started');
            add_contact_bubble('Let\'s go!', 'in');
        
            setTimeout(function() {
                contact_flow_next();
            }, 1000);    
        }
    });

    // On form submission - validate the input
    // and advance in the contact flow
    contact_form.on('submit', function(e) {
        e.preventDefault();

        var prompt = contact_field.val();

        if(prompt && prompt.length > 0) {

            $.ajax({
                type: 'POST',
                url: 'https://api.theo.gg/api/v1/contact',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify({
                    message: prompt,
                    step: contact_steps[is_contact[1]]
                }),
                beforeSend: function() {
                    add_contact_bubble(prompt, 'in');
                    toggle_contact_chat_wait_mode(true);
                },
                error: function(resp) {
                    var data = resp.responseJSON;

                    add_contact_bubble(contact_prompts[data.data], 'out');
                    toggle_contact_chat_wait_mode(false);
                },
                success: function() {

                    // Dispatch the contactFlowAnswered dataLayer event
                    window.dataLayer.push({
                        'event': 'contactFlowAnswered',
                        'step': contact_steps[is_contact[1]],
                        'prompt': prompt
                    });

                    contact_flow_next(prompt, 500);
                }
            });
        }
    });

    // Allow skipping optional questions
    contact_skip.on('click', function(e) {

        if(
            $(this).is(':visible') &&
            $.inArray(contact_steps[is_contact[1]], ['company', 'jobtitle', 'phone']) > -1
        ) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            // Dispatch a DataLayer event
            window.dataLayer.push({
                'event': 'contactQSkipped',
                'question': contact_steps[is_contact[1]]
            });
    
            var options = contact_prompts['skip'],
                rand    = Math.random() * (options.length - 0) + 0,
                prompt  = options[Math.floor(rand)];
    
            add_contact_bubble(prompt, 'in');
            contact_flow_next('skip', 1000);    
        }
    });

    // Helper - advance in the contact flow
    function contact_flow_next(prompt = undefined, initial_delay = 0) {

        // Append the info to the contact info array
        if(prompt !== undefined) {
            contact_info[contact_steps[is_contact[1]]] = prompt;
        }

        // Advance the track keeper of the contact flow by 1
        is_contact[1] = is_contact[1] + 1;

        if(contact_steps[is_contact[1]] && contact_steps[is_contact[1]].length > 0) {

            // Render the next contact prompt
            var message     = contact_prompts[contact_steps[is_contact[1]] + (prompt === 'skip' ? '_skipped' : '')],
                total_delay = 500 + initial_delay;

            if(Array.isArray(message)) {

                total_delay *= message.length;

                $.each(message, function(key) {

                    var partial = this;

                    setTimeout(function() {

                        add_contact_bubble(
                            partial, 
                            'out', 
                            (key === 0 ? undefined : contact_chat.find('li.out').last()), 
                            prompt
                        );

                    }, key * 500 + initial_delay);
                });

            } else {

                setTimeout(function() {

                    add_contact_bubble(
                        message, 
                        'out', 
                        undefined, 
                        prompt
                    );
                    
                }, 500 + initial_delay);
            }

            setTimeout(function() {
                toggle_contact_chat_wait_mode(false);
            }, total_delay);

            if($.inArray(contact_steps[is_contact[1]], ['company', 'jobtitle', 'phone']) > -1) {
                contact_skip.show();
            } else {
                contact_skip.hide();
            }

        } else {

            // Append the Hutk cookie to the contact info
            contact_info.hutk = Cookies.get('hubspotutk');

            // Send the data to the HubSpot API
            $.ajax({
                url: 'https://api.theo.gg/api/v1/hubspot',
                type: 'POST',
                async: false,
                data: JSON.stringify(contact_info),
                contentType: 'application/json; charset=utf-8',
                error: function() {

                    add_contact_bubble(
                        'Oops.. Something went wrong. Please try again..', 
                        'out', 
                        undefined, 
                        undefined,
                        false
                    );
                },
                success: function(data) {
                    
                    if(data.success) {

                        // Dispatch a contactFlowEnded dataLayer event
                        window.dataLayer.push({
                            'event': 'contactFlowEnded'
                        });

                        // Reset the contact mode params
                        is_contact[0] = false;
                        is_contact[1] = -1;
                        
                        add_contact_bubble(
                            'Thank you! I\'ll get back to you as soon as I can.', 
                            'out', 
                            undefined, 
                            undefined,
                            false
                        );

                        // Trigger the confetti as well
                        confetti({
                            particleCount: 150,
                            spread: 120,
                            origin: { 
                                y: 0.9 
                            },
                        });
                    }
                }
            });

            // Reset the form
            contact_skip.hide();
            contact_block.attr('data-state', 'initial');
            toggle_contact_chat_wait_mode(false, false);
        }
    }

    // Helper - render a contact bubble
    function add_contact_bubble(content, type = undefined, group = undefined, placeholder = undefined, skip = false) {

        toggle_contact_chat_wait_mode(true);

        var group_tmpl  = '<li class="' + type + '"></li>',
            image_tmpl  = '<figure class="round xs"><img src="/assets/images/me.jpeg" alt="Theodore Wolff" /></figure>',
            bubble_tmpl = '<p style="--anim: bubble-appear 0.4s ease-in-out forwards;">' + 
                (placeholder !== undefined ? content.replace('{}', placeholder) : content) + 
                '</p>';
        
        if(group === undefined) {
        
            $(group_tmpl).insertAfter(
                contact_chat.find('li').last()
            );
        
            if(type === 'out') {
                contact_chat.find('li.' + type).last()
                    .append(image_tmpl);
            }
        
            contact_chat.find('li.' + type).last()
                .append(bubble_tmpl);
                    
        } else {
            group.append(bubble_tmpl);
        }
        
        contact_chat_bar.getScrollElement().scrollTop = 
            contact_chat_bar.getScrollElement().scrollHeight;

        $('html, body').animate({ scrollTop: $(document).height() }, 0);
    }

    // Helper - toggle the wait mode on and off
    function toggle_contact_chat_wait_mode(on, refocus = true) {
        
        contact_block.find('input, button')
            .prop('disabled', on);
        
        if(on) {
            contact_field.val('').trigger('change');
            contact_block.find('label svg').show();
        } else {
            contact_block.find('label svg').hide();

            if(refocus) {
                contact_field[0].focus();
            }
        }
    }    
});